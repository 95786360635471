// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-dynamic-pages-realization-js": () => import("./../src/dynamicPages/realization.js" /* webpackChunkName: "component---src-dynamic-pages-realization-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-firmie-js": () => import("./../src/pages/o-firmie.js" /* webpackChunkName: "component---src-pages-o-firmie-js" */),
  "component---src-pages-realizacje-index-js": () => import("./../src/pages/realizacje/index.js" /* webpackChunkName: "component---src-pages-realizacje-index-js" */),
  "component---src-pages-realizacje-ukonczone-js": () => import("./../src/pages/realizacje/ukonczone.js" /* webpackChunkName: "component---src-pages-realizacje-ukonczone-js" */),
  "component---src-pages-realizacje-w-trakcie-js": () => import("./../src/pages/realizacje/w-trakcie.js" /* webpackChunkName: "component---src-pages-realizacje-w-trakcie-js" */),
  "component---src-pages-referencje-js": () => import("./../src/pages/referencje.js" /* webpackChunkName: "component---src-pages-referencje-js" */),
  "component---src-pages-uslugi-index-js": () => import("./../src/pages/uslugi/index.js" /* webpackChunkName: "component---src-pages-uslugi-index-js" */),
  "component---src-pages-uslugi-instalacje-przemyslowe-js": () => import("./../src/pages/uslugi/instalacje-przemyslowe.js" /* webpackChunkName: "component---src-pages-uslugi-instalacje-przemyslowe-js" */),
  "component---src-pages-uslugi-instalacje-sanitarne-wewnetrzne-js": () => import("./../src/pages/uslugi/instalacje-sanitarne-wewnetrzne.js" /* webpackChunkName: "component---src-pages-uslugi-instalacje-sanitarne-wewnetrzne-js" */),
  "component---src-pages-uslugi-instalacje-sanitarne-zewnetrzne-js": () => import("./../src/pages/uslugi/instalacje-sanitarne-zewnetrzne.js" /* webpackChunkName: "component---src-pages-uslugi-instalacje-sanitarne-zewnetrzne-js" */),
  "component---src-pages-uslugi-uslugi-dzwigowe-js": () => import("./../src/pages/uslugi/uslugi-dzwigowe.js" /* webpackChunkName: "component---src-pages-uslugi-uslugi-dzwigowe-js" */),
  "component---src-pages-uslugi-wynajem-mieszkan-js": () => import("./../src/pages/uslugi/wynajem-mieszkan.js" /* webpackChunkName: "component---src-pages-uslugi-wynajem-mieszkan-js" */),
  "component---src-pages-wydzielenie-spolki-js": () => import("./../src/pages/wydzielenie_spolki.js" /* webpackChunkName: "component---src-pages-wydzielenie-spolki-js" */)
}

